export default {
    path: '/integralmana',
    component: () => import("@/views/layout.vue"),
    redirect: '/integralmana',
    name: 'integralmana',
    meta: { title: '积分规则管理', role: '' },
    children: [
        {
            path: '/integralmana',
            name: 'integralmana',
            component: () => import('@/views/masterData/integralmana.vue'),
            meta: {
                title: '积分规则管理',
                role: ''
            }
        }
    ]
}