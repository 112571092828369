<template>
  <div class="loginBox">
    <div class="login">
      <h2>瀚维空间后台管理</h2>
      <h4>专业 智能 科技 放心托付</h4>
      <el-input
        class="loginInput"
        placeholder="请输入账号"
        prefix-icon="iconfont u-yonghu1"
        v-model="loginEntity.account"
        style="margin-top: 3vh"
      ></el-input>
      <el-input
        class="loginInput"
        placeholder="请输入密码"
        prefix-icon="iconfont u-mima"
        v-model="loginEntity.password"
        show-password
      ></el-input>
      <el-button @click="login" type="primary">登录</el-button>
    </div>
    <div class="copyrightInfo">
      <img src="@/assets/imgs/companylogo.png" alt="加载失败" />
      <span>上海联泉智能科技有限公司 提供技术支持</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data () {
    return {
      loginEntity: {
        account: "",
        password: "",
      },
    }
  },
  methods: {
    async login () {
      let result = await this.$http.request({
        showLoading: true,
        url: "/hw/admin/user/user_login",
        method: "post",
        params: this.loginEntity,
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (result.status == 100) {
        let { data } = result
        this.lsSet("userInfo", {
          userName: data.userName,
          token: data.token,
          userAccount: data.userAccount,
          userId: data.userId,
        })
        this.jump("/home")
      } else {
        this.$message.error("密码错误,请重新输入密码")
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
