import Vue from "vue"
import VueRouter from "vue-router"
import Layout from "../views/layout.vue"
import Login from "../views/login.vue"

Vue.use(VueRouter)

const routerList = []
let r = require.context("./routes", false, /\.routes\.js/)
r.keys().forEach((key) => {
    if (r(key).default) routerList.push(r(key).default)
})

const routes = [
    {
        path: "/login",
        name: "Login",
        hidden: true,
        component: Login,
    },
    {
        path: "/",
        redirect: "/login",
    },
    {
        path: "/401",
        name: "401",
        component: () => import("@/views/errorPage/401"),
        hidden: true,
    },
    {
        path: "/404",
        name: "404",
        component: () => import("@/views/errorPage/404"),
        hidden: true,
    },
    {
        // 首页
        path: "/home",
        component: Layout,
        children: [
            {
                path: "/home",
                name: "Home",
                component: () => import("@/views/Home/home.vue"),
                meta: {
                    title: "首页",
                    icon: 'iconfont u-shouye',
                    cantClose: true
                },
            },
            {
                path: '/releas_detail',
                name: 'releas_detail',
                component: () => import('@/views/masterData/releas_detail.vue'),
                meta: {
                    title: '商业发布',
                    role: ''
                }
            },
            {
                path: '/release_xiu/:artId',
                name: 'release_xiu',
                component: () => import('@/views/masterData/release_xiu.vue'),
                meta: {
                    title: '商业发布编辑',
                    role: ''
                }
            },
            {
                path: '/testPage_detail/:userId',
                name: 'testPage_detail',
                component: () => import('@/views/masterData/testPage_detail.vue'),
                meta: {
                    title: '认证审核',
                    role: ''
                }
            },
            {
                path: '/share_detail/:artId',
                name: 'share_detail',
                component: () => import('@/views/masterData/share_detail.vue'),
                meta: {
                    title: '精英每日分享审核',
                    role: ''
                }
            },
            {
                path: '/acti_details/:atyId',
                name: 'acti_details',
                component: () => import('@/views/masterData/acti_details.vue'),
                meta: {
                    title: '活动召集审核',
                    role: ''
                }
            },
            {
                path: '/Help_details/:artId',
                name: 'Help_details',
                component: () => import('@/views/masterData/Help_details.vue'),
                meta: {
                    title: '互助内容审核',
                    role: ''
                }
            },
            {
                path: '/recrui_detail/:recruitId',
                name: 'recrui_detail',
                component: () => import('@/views/masterData/recrui_detail.vue'),
                meta: {
                    title: '招聘合作审核',
                    role: ''
                }
            },
            {
                path: '/useradmin_details/:userId',
                name: 'useradmin_details',
                component: () => import('@/views/masterData/useradmin_details.vue'),
                meta: {
                    title: '人员管理',
                    role: ''
                }
            },
        ],
    },
    {
        // 个人中心
        path: "/personal",
        component: Layout,
        redirect: "/home",
        children: [
            {
                path: "/personal",
                name: 'Personal',
                component: () => import("@/views/Personal/personal.vue"),
                meta: {
                    title: "个人中心",
                    role: 'personal'
                },
            },
        ],

    },
    ...routerList,

    { path: "*", redirect: "/404", hidden: true },
]

const router = new VueRouter({
    // mode: "history",
    base: process.env.BASE_URL,
    routes,
})

//路由重复跳转报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch((err) => err)
}

router.beforeEach((to, from, next) => {
    //过滤页面
    if (to.fullPath != "/login" && to.fullPath != "/404" && to.fullPath != "/401") {
        //未登录时跳转到登陆页
        if (localStorage.getItem('userInfo') == '{}' || !localStorage.getItem('userInfo')) {
            next({
                path: '/login'
            })
        } else {
            next()
        }
        // next({
        //   path: '/401'
        // })
    } else {
        next()
    }
})

export default router
