export default {
    allAuths: [],
    setAuths (list) {
        // localStorage.setItem('allAuths',JSON.stringify(resp.data.authorities.auths))//权限
        // this.$permission.setAuths(resp.data.authorities.auths)
        if (list && list.length) {
            this.allAuths = list
        } else {
            this.allAuths = JSON.parse(localStorage.getItem('allAuths')) || []
        }
    },
    Check (key) {
        if (key) {
            for (let i in this.allAuths) {
                if (this.allAuths[i] == key)
                    return true
            }
            return false
        }
        return true
    }
}