export default {
    path: '/statistical',
    component: () => import("@/views/layout.vue"),
    redirect: '/statistical',
    name: 'statistical',
    meta: { title: '用户查看与统计', role: '' },
    children: [
        {
            path: '/statistical',
            name: 'statistical',
            component: () => import('@/views/masterData/statistical.vue'),
            meta: {
                title: '搜索与按钮使用',
                role: ''
            }
        },
        {
            path: '/usertical',
            name: 'usertical',
            component: () => import('@/views/masterData/usertical.vue'),
            meta: {
                title: '用户发布统计',
                role: ''
            }
        },
    ]
}