export default {
    path: '/userControl',
    component: () => import("@/views/layout.vue"),
    redirect: '/userControl_test',
    name: 'userControl',
    meta: { title: '内容审核', role: '' },
    children: [
        {
            path: '/shareControl',
            name: 'shareControl',
            component: () => import('@/views/masterData/shareControl.vue'),
            meta: {
                title: '精英分享审核',
                role: ''
            }
        }, {
            path: '/HelpControl',
            name: 'HelpControl',
            component: () => import('@/views/masterData/HelpControl.vue'),
            meta: {
                title: '互助内容审核',
                role: ''
            }
        }, {
            path: '/actiControl',
            name: 'actiControl',
            component: () => import('@/views/masterData/actiControl.vue'),
            meta: {
                title: '活动召集审核',
                role: ''
            }
        }, {
            path: '/recruiControl',
            name: 'recruiControl',
            component: () => import('@/views/masterData/recruiControl.vue'),
            meta: {
                title: '招聘合作审核',
                role: ''
            }
        },
    ]
}
