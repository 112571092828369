import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// elementUi
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// http
import Http from '@/libs/http.js'

import utils from '@/libs/utils.js'
//权限
import Permission from "./libs/permission"
//公用组件 
import components from './components/index'
//图标文件
import '@/assets/iconFont/iconfont.css'
//主要样式
import '@/assets/styles/main.scss'
//自定义主题颜色
import '@/assets/styles/theme.scss'
import VueQuillEditor from 'vue-quill-editor'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor,
    /* { default global options } */)

Vue.use(utils)
Vue.use(components)
Vue.use(ElementUI)
Vue.prototype.$http = Http

Vue.config.productionTip = false

//权限校验
//v-authority="'权限代码'" 进行使用
//权限校验规则 src/libs/permission.js
Vue.directive("authority", {
    inserted (el, binding) {
        if (!Permission.Check(binding.value))
            el.parentNode && el.parentNode.removeChild(el)
    },
})
Permission.setAuths(['personal', 'masterData', 'TestPage01'])//权限存入

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
