export default {
    path: '/releaseentry',
    component: () => import("@/views/layout.vue"),
    redirect: '/releaseentry',
    name: 'releaseentry',
    meta: { title: '商业发布录入', role: '' },
    children: [
        {
            path: '/releaseentry',
            name: 'releaseentry',
            component: () => import('@/views/masterData/releaseentry.vue'),
            meta: {
                title: '商业发布录入',
                role: ''
            }
        }
    ]
}