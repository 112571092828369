export default {
    path: '/useradmin',
    component: () => import("@/views/layout.vue"),
    redirect: '/useradmin',
    name: 'useradmin',
    meta: { title: '系统管理', role: 'masterData' },
    children: [
        {
            path: '/useradmin',
            name: 'useradmin',
            component: () => import('@/views/masterData/useradmin.vue'),
            meta: {
                title: '人员管理',
                role: ''
            }
        },
        // {
        //     path: '/userrole',
        //     name: 'userrole',
        //     component: () => import('@/views/masterData/userrole.vue'),
        //     meta: {
        //         title: '角色管理',
        //         role: ''
        //     }
        // }
    ]
}